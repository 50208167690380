import * as React from 'react';
import { Translate } from 'react-localize-redux';
import FormBuilder from '../../containers/formBuilder/FormBuilder';
import FormElement from '../../containers/formBuilder/formElement/FormDetail';
import FormDataList from '../../containers/formBuilder/formData/FormDataList';
import FormDataDetail from '../../containers/formBuilder/formData/FormDataDetail';
import { Route } from '../Routes';
import FieldRepportRouteBlack from 'images/menu_icon/fieldReport_black.svg';
import FieldRepportRouteBlue from 'images/menu_icon/fieldReport_blue.svg';
import FieldRepportRouteWhite from 'images/menu_icon/fieldReport_white.svg';
import FormTemplateCreator from '../../containers_v2/forms/FormTemplateCreator';
import FormInstanceView from '../../containers_v2/forms/instance-list/FormInstanceView';
import { VisualFormEditor } from '../../containers_v2/forms/visual-form-editor/visualFormEditor';

const FormsRoutes = (): Route[] => [
	{
		component: FormBuilder,
		path: '/formBuilder',
		name: <Translate id="sidebar.sales_operations" />,
		key: 'sidebar.sales_operation',
		sectionKey: 'forms',
		logoImg: FieldRepportRouteBlack,
		hoverImg: FieldRepportRouteBlue,
		activeImg: FieldRepportRouteWhite,
		title: 'EnForm',
		subTitle: 'EnForm',
		restricted: false,
		exact: true,
		menu: true,
		permission: ['ViewFreeForm', 'ViewShelfAudit', 'ViewOrder', 'ViewOpportunity', 'ViewCheckout', 'ViewCampaign'],
		subroutes: [
			{ path: '/orders', name: <Translate id="sidebar.orders" />, permission: ['ViewOrder'] },
			{ path: '/opportunities', name: <Translate id="opportunities" />, permission: ['ViewOpportunity'] },
			{ path: '/shelf-audits', name: <Translate id="sidebar.shelf_audits" />, permission:['ViewShelfAudit'] },
			{ path: '/formBuilder', name: <Translate id="free_forms" />, permission: ['ViewFreeForm'] },
		]
	},
	{
		component: FormTemplateCreator,
		path: '/form-template'
	},
	{
		component: FormInstanceView,
		path: '/form-instance-list'
	},
	{
		component: FormElement,
		path: '/formBuilder/create',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	},
	{
		component: FormDataList,
		path: '/formBuilder/formData/:id/list',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		subPath: 'targetManagement',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	},
	{
		component: FormDataList,
		path: '/formBuilder/formData/:id/list/:linkedId',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		subPath: 'targetManagement',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	},
	{
		component: FormDataList,
		path: '/formBuilder/formData/:id/list/:linkedId/:type',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		subPath: 'targetManagement',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	},
	{
		component: FormDataDetail,
		path: '/formBuilder/formData/:id/create',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	},
	{
		component: FormDataDetail,
		path: '/formBuilder/formData/:id/:type/:idForm',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	},
	{
		component: FormElement,
		path: '/formBuilder/:type/:id',
		name: <Translate id="forms" />,
		title: <Translate id="forms" />,
		subTitle: 'Form Builder',
		restricted: false,
		exact: true,
		menu: false,
		sectionKey: 'forms'
	}
];

export default FormsRoutes;
