import { Editor } from '@monaco-editor/react';
import * as React from 'react';
import { PanelSelector } from '../../components_v2/Selector/PanelSelector';
import styled from 'styled-components';
import { FlexDiv } from '../products/style';
import { translateToString } from '../../styles/global/translate';
import luaPath from 'images/icons/lua-icon.svg';
import JSON_VALIDATOR, { Screen } from './jsonValidator';
import { LoadingStateEnum } from '../import/model';
import { MonacoError } from './FormTemplateCreator';
import { Field } from '../../atoms/forms';
import { useFunctionState } from '../../utils/customHooks';

const JsonLogo = styled.div`
	width: 17px;
	height: 17px;
	&:after {
		content: "{ }";
    	color: #e3c024;
    	font-size: 11px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	height: 100%;
    	width: 100%;
	}
`;

// eslint-disable-next-line react/display-name
const AdvancedFormEditor = React.forwardRef((props: {
	setLoadingState: React.Dispatch<React.SetStateAction<LoadingStateEnum>>,
	setErrors: React.Dispatch<React.SetStateAction<object[][]>>,
	screens: Screen[],
	setScreens: React.Dispatch<React.SetStateAction<Screen[]>>,
	lib: string | undefined,
	setLib: React.Dispatch<React.SetStateAction<string | undefined>>,
}, parentRef) => {
	const { screens, setScreens, lib, setLib } = props;
	const editorRef = React.useRef<any>(null);
	const [refresh, setRefresh] = React.useState({});
	const [, setPositions] = React.useState<{ column: number, lineNumber: number }[]>([]);
	const [panelIndex, setPanelIndex, setPanelNoFn] = useFunctionState<number>(0, ({ oldValue, newValue }) => {
		// TODO remove this and use tabs
		setPositions(positions => {
			const visibleRange = editorRef.current.getVisibleRanges()[0];
			positions[oldValue] = { column: visibleRange.startColumn, lineNumber: visibleRange.startLineNumber };
			const oldPosition = positions[newValue] ?? { column: 1, lineNumber: 1 };
			setTimeout(() => {
				editorRef.current?.getAction('editor.action.formatDocument').run();
			}, 100);
			setTimeout((oldPosition) => {
				editorRef.current?.setPosition(oldPosition);
				editorRef.current.revealLine(oldPosition.lineNumber);
			}, 200, oldPosition);
			return [...positions];
		});
		return newValue;
	});

	React.useImperativeHandle(parentRef, () => ({
		onNewScreen() {
			setRefresh({});
			editorRef.current?.getAction('editor.action.formatDocument').run();   
			setTimeout(() => editorRef.current?.getAction('editor.action.formatDocument').run(), 100);
		},
		onErrorClicked(error: MonacoError) {
			if (error.source === 'json' || error.owner === 'json') {
				setPanelNoFn(0);
			} else {
				setPanelNoFn(1);
			}
			setTimeout(() => {
				editorRef.current.focus();
				editorRef.current.setPosition({ column: error.startColumn, lineNumber: error.startLineNumber });
				editorRef.current.revealLine(error.startLineNumber);
			}, 50);
		},
		onFieldClicked(field: Field) {
			if (!editorRef.current) return;
			const selection = editorRef.current.getSelection();
			const id = { major: 1, minor: 1 };             
			const op = { identifier: id, range: selection, text: `{ "field_id": ${field.id}, "metadata": {}, "slug": "${field.slug}" }`, forceMoveMarkers: true };
			editorRef.current.executeEdits('my-source', [op]);
		}
	}), [editorRef]);
	
	const PANELS = [
		{
			label: <FlexDiv gap='0.5em'> <JsonLogo /> {translateToString('screens')}</FlexDiv>,
			language: 'json'
		},
		{
			label: <FlexDiv gap='0.5em'> <img src={luaPath} width={17} height={17} /> {translateToString('librairy')}</FlexDiv>,
			language: 'lua'
		}
	];

	return <>
		<PanelSelector
			square
			panels={PANELS}
			onClick={(_, panelIndex) => setPanelIndex(panelIndex)}
			activeIndex={panelIndex}
			noToolTip
			minWidth='250px'
		/>
		<div style={{ backgroundColor: 'white', height: 'calc(100% - 29px)' }}>
			<Editor
				height='100%'
				value={React.useMemo(() => panelIndex === 1 ? lib : JSON.stringify(screens), [panelIndex, refresh])}
				language={React.useMemo(() => PANELS[panelIndex].language, [panelIndex])}
				onChange={React.useCallback((value) => panelIndex === 1 ? setLib(value) : setScreens(JSON.parse(value)), [panelIndex])}
				onValidate={React.useCallback((err) => {
					props.setErrors(errors => {
						errors[panelIndex] = err;
						return [...errors];

					});
				}, [panelIndex])}
				onMount={React.useCallback((editor, monaco) => {
					editorRef.current = editor;
			
					if (monaco.languages.json?.jsonDefaults?._diagnosticsOptions?.schemas?.length === 0) {
						monaco.languages.registerHoverProvider('json', {
							provideHover: function(model, position) { 
								const documentation = getJsonDocumentation(model.getWordAtPosition(position)?.word);
								if (!documentation) return;
								return {
									range: new monaco.Range(
										1,
										1,
										model.getLineCount(),
										model.getLineMaxColumn(model.getLineCount())
									),
									contents: [
										{
											value: documentation
										},
									]
								};
							}
						});
						monaco.languages.json.jsonDefaults.setDiagnosticsOptions(JSON_VALIDATOR);
					}

					setTimeout(() => editor.getAction('editor.action.formatDocument').run(), 100);
					editor.getAction('editor.action.formatDocument').run();

					props.setLoadingState(LoadingStateEnum.LOADED);
				}, [])}
			/>
		</div>
	</>;
});


function getJsonDocumentation(word: string | null): string | undefined {
	if (!word) return;
	switch (word) {
		case 'screen_name': return 'Titre de l\'écran';
		case 'next_button': return 'Titre du boutton "Suivant"';
		case 'previous_button': return 'Titre du boutton "Précédent"';
		case 'next_screens': return 'Liste des écrans suivant';
		case 'condition': return 'Script lua déterminant si on peux changer de page ou non';
		case 'screen_index': return 'Index de la prochaine page';
		case 'field_index': return 'Index du champ dans la liste `fields`';
		case 'type': return 'Type du champ.';
		case 'fields': return 'Liste des champs';
		case 'field_id': return 'Id du champ';
		case 'layout': return 'Liste de l\'agencement de la page';
		case 'components': return 'Liste des composants';
		case 'component_index': return 'Index du composant dans la liste `components`';
		case 'default': return 'Valeur par défault du champ';
		case 'enabled_if': return 'Script lua déterminant si le champs est activé ou non';
		case 'visible_if': return 'Script lua déterminant si le champs est visible ou non';
		case 'validator': return 'Script lua déterminant si le champs est validé ou non';
		case 'children': return 'Liste des enfant du champ `layout`';
		case 'screen':
		case 'data':
		case 'product':
		case 'metadata': return undefined;
	}
}

export default AdvancedFormEditor;