import * as React from 'react';
import 'reactflow/dist/style.css';
import { Layout } from '../jsonValidator';
import * as _ from 'lodash';
import { FormEditorContext } from './visualFormEditor';
import { AddComponent, ComponentEditor, ComponentsLibrary, BoolEditor, ErrorWrapper, JsonToStringEditorWithSaveButton, RemoveLink, StringEditor, Wrapper, GroupWrapper, ComponentWrapper, VisibleIfEditor, ValidatorEditor } from './componentsEditor';
import {
	addExistingComponentToGrid,
	addFieldToGrid,
	addNewComponentToGrid,
	fieldValidator,
	getComponent,
	getComponentPath,
	getField,
	getFieldPath,
	getParentArray,
	getParentArrayIndex,
	luaInValidatorOrVisibleIfValidator,
	removeComponent,
	removeFieldFromGrid,
	swapInLayout
} from './formProcessingTools';
import { Translate, translateToNode, translateToString } from '../../../styles/global/translate';
import styled from 'styled-components';
import Popup from '../../../components_v2/popup/Popup';
import { PopupMode } from '../../../components_v2/popup/model/Model';
import DeleteIcon from 'images/icon/delete.png';
import SettingIcon from 'images/setting_icons/system_setting_icon.svg';
import useAlert from '../../alert/UseAlert';
import { AlertRes } from '../../alert/AlertProvider';
import { ButtonStyle } from '../../../components_v2/popup/PopupCreation';
import Up from 'images/icons/Up.svg';
import Down from 'images/icons/Down.svg';
import { Field, getFields } from '../../../atoms/forms';
import { LeftModal } from '../FormTemplateCreator';
import { kebabCase } from 'lodash';
import { assert } from '../../../utils/assert';
import Add from '../../../components_v2/add/Add';
import { TitleAndChild } from '../../client-companies/popup/Detail';
import { LightBlueSidely } from '../../../styles/global/css/Utils';
import { FlexDiv } from '../../products/style';


export const Icon = styled.div`
	margin-left: 5px;
	cursor: pointer;
	display: inline-block;
`;

export const FieldWrapper = styled.div`
	width: 100%;
	padding: 8px;
	border-radius: 5px;
	margin-bottom: 5px;
	margin-top: 5px;

	&:hover {
		background-color: ${LightBlueSidely};
	}
`;

export function EditComponentPopup(props: { path: string, pageNumber: number, name: string }): JSX.Element {
	const { form } = React.useContext(FormEditorContext);
	const component_layout: Layout = _.get(form[props.pageNumber], props.path);
	const pageName = form[props.pageNumber].name;
	if (!pageName || !component_layout.name)
		return <>
			erreur
			<br />
			path: {props.path}
		</>;
	
	const componentPath = getComponentPath(form, props.pageNumber, component_layout.name);
	const component = getComponent(form, pageName, component_layout.name);

	return <>
		<div style={{ overflow: 'scroll', height: '100%' }}>
			<b>{component_layout.name}</b>
			<br />
			{component && <span style={{ fontSize: '12px' }}>
				type: {component.type} <br />
				slug: {component_layout.name} <br />
			</span>}
			<VisibleIfEditor
				path={props.path + '.visible_if'}
				pageNumber={props.pageNumber}
			/>

			{componentPath && <> metadata:
				<JsonToStringEditorWithSaveButton
					path={componentPath + '.metadata'}
					pageNumber={props.pageNumber}
					label='metadata'
				/>
				<br />
			</>}

			{componentPath && <>default value:
				<JsonToStringEditorWithSaveButton
					path={componentPath + '.default'}
					label='default value'
					pageNumber={props.pageNumber}
				/>
				<br />
			</>}
		</div>
	</>;

}

export function EditFieldPopup(props: { path: string, pageNumber: number, name: string, dbField: Field | undefined}): JSX.Element {
	const { form } = React.useContext(FormEditorContext);
	const field: Layout = _.get(form[props.pageNumber], props.path);
	const pageName = form[props.pageNumber].name;
	const [fields, setFields] = React.useState<Field[]>();


	React.useEffect(() => {
		getFields().then(setFields);
	}, []);

	if (!pageName || !field || !field.name || !props.dbField)
		return <>
			erreur
			<br />
			path: {props.path}
		</>;
	const fieldPath = getFieldPath(form, pageName, field.name);
	if (pageName && field.name && fields) {
		return <>
			<div style={{ overflow: 'scroll' }}>
				<b>{props.dbField.name}</b>
				<br />
				<span style={{ fontSize: '12px' }}>
				type: {props.dbField.type} <br />
				slug: {field.name} <br />

				</span>
				<VisibleIfEditor
					path={props.path + '.visible_if'}
					pageNumber={props.pageNumber}
				/>

				<ValidatorEditor
					path={fieldPath + ''}
					pageNumber={props.pageNumber}
				/>
	
				<Translate id='form_editor.historise' />
				<BoolEditor path={fieldPath + '.history'} pageNumber={props.pageNumber} />

			metadata:
				<JsonToStringEditorWithSaveButton
					path={fieldPath + '.metadata'}
					pageNumber={props.pageNumber}
					label='metadata'
				/>
				<br />


			default value:
				<JsonToStringEditorWithSaveButton
					path={fieldPath + '.default'}
					label='default value'
					pageNumber={props.pageNumber}
				/>
				<br />

			calculated_field:
				<StringEditor
					path={fieldPath + '.calculated_value'}
					pageNumber={props.pageNumber}
				/>
			</div>
		</>;
	}
	else {
		return <>
			erreur
			path: {props.path}
		</>;
	}
}


export function AddToLayoutAndFields(props: { path: string, pageNumber: number, layout: Layout, fieldList: Field[], addTo?: string }): JSX.Element {
	const [addFieldPopupOpen, setaddFieldPopupOpen] = React.useState<boolean>(false);
	const [addComponentPopupOpen, setaddComponentPopupOpen] = React.useState<boolean>(false);
	const { form, setForm } = React.useContext(FormEditorContext);

	const alert = useAlert();


	const pageName = form[props.pageNumber].name;

	return <>
		<Popup
			popupStyle={{ noTransition: true }}
			isOpen={addFieldPopupOpen}
			popupMode={PopupMode.Details}
			onClickOut={() => setaddFieldPopupOpen(false)}
			content={<>
				<LeftModal externalUse={true}
					creationNotAllowed={true}
					allowEdit={false}
					onFieldClicked={(f) => {
						if (pageName) {
							const newForm = _.cloneDeep(form);
							setForm(addFieldToGrid(newForm, pageName, {
								name: kebabCase(f.name), field_id: f.id, metadata: {
									'catalogue': null,
									'product': null
								}
							}, props.path));
						}
					}}
					errors={[]}
				/>
			</>}
		/>

		<Popup
			popupStyle={{ noTransition: true }}
			isOpen={addComponentPopupOpen}
			popupMode={PopupMode.Details}
			onClickOut={() => setaddComponentPopupOpen(false)}
			content={<>
				<div style={{ overflow: 'scroll', height: '100%' }}>
				Composants existant dans cette page:
					<Wrapper>
						{form[props.pageNumber].components.map((component, index) => {
							return <><AddComponent key={index} onClick={() => {
								if (pageName && component.name) {
									const newForm = _.cloneDeep(form);
									setForm(addExistingComponentToGrid(newForm, pageName, component.name, props.path));
									setaddComponentPopupOpen(false);
								}
							}}>{component.name}
								<img src={DeleteIcon} alt='delete' height={'20px'} onClick={ev => {
									ev.stopPropagation();
									alert({
										title: translateToNode('import.are_you_sure'),
										content: <>{translateToString('form_editor.delete_component')}{component.name} ?</>,
										buttons: [
											{
												title: translateToNode('cancel'),
												res: AlertRes.Break,
												style: ButtonStyle.White
											},
											{
												title: translateToNode('yes'),
												res: AlertRes.Ok,
												style: ButtonStyle.Error
											}
										]
									}).then(res => {
										if (pageName && component.name && res === AlertRes.Ok) {
											setForm(removeComponent(form, props.pageNumber, component.name));
										}
									});
								}} />
							</AddComponent>

							</>;
						})}
					</Wrapper>
				Créer un nouveau composant: 
					<Wrapper>
						<ComponentsLibrary
							setComponent={(component) => {
								if (pageName && component && component.name) {
									const newForm = _.cloneDeep(form);
									setForm(addNewComponentToGrid(newForm, pageName, component, props.path));
									setaddComponentPopupOpen(false);
								}
							}}
						/>
					</Wrapper>
				</div>
			</>}
		/>

		<Translate id='form_editor.add_field_to' />
		<Add
			onClick={() => setaddFieldPopupOpen(true)}
		/>
		<br />
		Ajouter un composant ici
		<Add
			onClick={() => setaddComponentPopupOpen(true)}
		/>
	</>;
}



export function EditChildrenLayout(props: { path: string, pageNumber: number, layout: Layout, fieldList: Field[] }): JSX.Element {
	assert(props.layout.children !== undefined, 'children is undefined');

	return <>
		{
			props.layout.children.map((child, index) => {
				return <>
					<EditLayout path={`${props.path}[${index}]`}
						pageNumber={props.pageNumber}
						layout={child}
						fieldList={props.fieldList}
					/>
				</>;
			})
		}
		<br/>
		<AddToLayoutAndFields path={props.path} pageNumber={props.pageNumber} layout={props.layout} fieldList={props.fieldList} addTo={props.layout.name} />
	</>;
}

export function EditSingleChildLayout(props: { path: string, pageNumber: number, layout: Layout, fieldList: Field[] }): JSX.Element {
	assert(props.layout.child !== undefined, 'child is undefined');
	return <>
		<EditLayout path={props.path}
			pageNumber={props.pageNumber}
			layout={props.layout.child}
			fieldList={props.fieldList}
		/>
	</>;
}

export function EditComponentColumns(props: { path: string, pageNumber: number, layouts: Layout[], fieldList: Field[] }): JSX.Element {
	return <>
		<Translate id='form_editor.component_list.card_list' />
		{props.layouts.map((layout, index) => {
			return <>
				<EditLayout
					key={index}
					path={`${props.path}[${index}]`}
					pageNumber={props.pageNumber}
					layout={layout}
					fieldList={props.fieldList}
				/>
			</>;
		})}
	</>;

}

export function EditSimpleLayout(props: {path: string, pageNumber: number, layout: Layout, fieldList: Field[]}): JSX.Element {
	const alert = useAlert();
	const { form, setForm } = React.useContext(FormEditorContext);
	const pageName = form[props.pageNumber].name;
	const [editFieldOpen, setEditFieldOpen] = React.useState<boolean>(false);

	const fieldDB = props.fieldList.find(f => f.id === getField(form, pageName ?? '', props.layout.name ?? '')?.field_id);

	const index = getParentArrayIndex(form, props.pageNumber, props.path);

	return <>
		{props.layout.name && <Popup
			popupStyle={{ noTransition: true }}
			isOpen={(props.layout.name !== undefined) && editFieldOpen}
			popupMode={PopupMode.Details}
			onClickOut={() => setEditFieldOpen(false)}
			content={<EditFieldPopup path={props.path} pageNumber={props.pageNumber} name={props.layout.name} dbField={fieldDB} />}
		/>}
		{fieldDB && fieldDB.name}
		
		{pageName && props.layout.name && getField(form, pageName, props.layout.name)?.validator && !luaInValidatorOrVisibleIfValidator(form, pageName, getField(form, pageName, props.layout.name)?.validator) && <ErrorWrapper>Erreur: le validator {getField(form, pageName, props.layout.name)?.validator} est pas valide</ErrorWrapper>}
		{pageName && props.layout.name && props.layout.visible_if && !luaInValidatorOrVisibleIfValidator(form, pageName, props.layout.visible_if) && <ErrorWrapper>Erreur: le visible_if {props.layout.visible_if} est pas valide</ErrorWrapper>}
		<Icon
			onClick={
				() => setEditFieldOpen(true)
			}
		>
			<img src={SettingIcon} alt='settings' height={'20px'} />
		</Icon>
		<Icon
			onClick={
				() => {
					alert({
						title: translateToNode('import.are_you_sure'),
						content: <>{translateToString('form_editor.delete_field')}{props.layout.name} ?</>,
						buttons: [
							{
								title: translateToNode('cancel'),
								res: AlertRes.Break,
								style: ButtonStyle.White
							},
							{
								title: translateToNode('yes'),
								res: AlertRes.Ok,
								style: ButtonStyle.Error
							}
						]
					}).then(res => {
						if (pageName && props.layout.name && res === AlertRes.Ok) {
							setForm(removeFieldFromGrid(form, props.pageNumber, props.layout.name, props.path));
						}
					});
				}
			}
		>
			<img src={DeleteIcon} alt='delete' height={'20px'} />
		</Icon>
		{(index > 0) && <Icon
			onClick={
				() => {
					setForm(swapInLayout(form, props.pageNumber, props.path, -1));
				}
			}
		>
			<img src={Up} alt='up' height={'20px'} />
		</Icon>}
		{(index + 1 !== getParentArray(form, props.pageNumber, props.path).length) && <Icon
			onClick={
				() => {
					setForm(swapInLayout(form, props.pageNumber, props.path, 1));
				}
			}
		>
			<img src={Down} alt='down' height={'20px'} />
		</Icon>}
	</>;
}

export function EditLayout(props: { path: string, pageNumber: number, layout: Layout, fieldList: Field[] }): JSX.Element {
	const { form, setForm } = React.useContext(FormEditorContext);
	const pageName = form[props.pageNumber].name;
	const fieldName = props.layout.name;
	const alert = useAlert();
	const [editFieldOpen, setEditFieldOpen] = React.useState<boolean>(false);


	if (props.layout) {
		switch (props.layout.type) {
			case 'field':
				if (pageName && fieldName && fieldValidator(form, pageName, fieldName)) {
					return <>
						<FieldWrapper>
							<EditSimpleLayout
								path={props.path}
								pageNumber={props.pageNumber}
								layout={props.layout}
								fieldList={props.fieldList}
							/>
						</FieldWrapper>
					</>;
				} else {
					return <ErrorWrapper>Erreur: le champ {fieldName} est pas un champ ou composant existant,
						vous devriez <RemoveLink
						onClick={() => {
							fieldName && setForm(removeFieldFromGrid(form, props.pageNumber, fieldName, props.path));
						}}
					>le supprimer</RemoveLink></ErrorWrapper>;
				}
			case 'container':
			case 'expanded_section':
				return <>
					<EditSingleChildLayout
						path={`${props.path}.child`}
						pageNumber={props.pageNumber}
						layout={props.layout}
						fieldList={props.fieldList}
					/>
				</>;
			case 'row':
			case 'column':
				return <>
					<GroupWrapper>
						<TitleAndChild
							titleStyle={{ margin: '5px' }}
							title='Groupe de champs et composants'>
							<div
								style={{ width: '93%' }}
							>
								<EditChildrenLayout
									path={`${props.path}.children`}
									pageNumber={props.pageNumber}
									layout={props.layout}
									fieldList={props.fieldList}
								/>
							</div>
						</TitleAndChild>
					</GroupWrapper>
				</>;
			case 'component': {
				const component_path = getComponentPath(form, props.pageNumber, props.layout.name);
				const component = getComponent(form, pageName, props.layout.name);

				if (!component_path || !component) {
					return <>
						<Wrapper warningType='error'>
							Erreur: le composant {props.layout.name} existe pas sur cette page
							Vous devriez <RemoveLink
								onClick={() => {
									props.layout.name && setForm(removeFieldFromGrid(form, props.pageNumber, props.layout.name, props.path));
								}}
							>le supprimer</RemoveLink>
						
						</Wrapper>
						
					</>;
				} else {
					return <>
						{props.layout.name && <Popup
							popupStyle={{ noTransition: true }}
							isOpen={(props.layout.name !== undefined) && editFieldOpen}
							popupMode={PopupMode.Details}
							onClickOut={() => setEditFieldOpen(false)}
							content={<EditComponentPopup path={props.path} pageNumber={props.pageNumber} name={props.layout.name} />}
						/>}
						{pageName && !luaInValidatorOrVisibleIfValidator(form, pageName, props.layout.visible_if) && <>Erreur: le visible_if {props.layout.visible_if} est pas valide</>}
						<FlexDiv>
							<ComponentWrapper>
								{pageName && !luaInValidatorOrVisibleIfValidator(form, pageName, props.layout.visible_if) && <ErrorWrapper>
									Erreur: le visible_if {props.layout.visible_if} est pas valide
								</ErrorWrapper>}
								{component_path && component && <ComponentEditor
									path={component_path}
									pageNumber={props.pageNumber}
									component={component}
								/>}
							</ComponentWrapper>
							<FlexDiv
								flow='column' align='stretch'>
								<img src={SettingIcon} alt='settings' height={'20px'}
									onClick={ev => {
										ev.stopPropagation();
										setEditFieldOpen(true);
									}}
								/>
								<img src={DeleteIcon} alt='Delete' height={'20px'} onClick={ev => {
									ev.stopPropagation();
									alert({
										title: translateToNode('import.are_you_sure'),
										content: <>{translateToString('form_editor.delete_component')}{props.layout.name} ?</>,
										buttons: [
											{
												title: translateToNode('cancel'),
												res: AlertRes.Break,
												style: ButtonStyle.White
											},
											{
												title: translateToNode('yes'),
												res: AlertRes.Ok,
												style: ButtonStyle.Error
											}
										]
									}).then(res => {
										if (pageName && fieldName && res === AlertRes.Ok) {
											setForm(removeFieldFromGrid(form, props.pageNumber, fieldName, props.path));
										}
									});
								}} />
								{getParentArrayIndex(form, props.pageNumber, props.path) > 0 && <Icon
									onClick={() => {
										setForm(swapInLayout(form, props.pageNumber, props.path, -1));
									}}>
									<img src={Up} alt='up' height={'20px'} /></Icon>}
								{getParentArrayIndex(form, props.pageNumber, props.path) + 1 < getParentArray(form, props.pageNumber, props.path).length && <Icon
									onClick={() => {
										setForm(swapInLayout(form, props.pageNumber, props.path, 1));
									}}>
									<img src={Down} alt='down' height={'20px'} /></Icon>}

							</FlexDiv>
						</FlexDiv>
					</>;
				}			
			}
			default:
				return <>Unkown layout type</>;
		}
	} else {
		return <></>;
	}
}
