import axios from 'axios';
import { FilterType } from 'bindings/filters/FilterType';
import { GenericReport, MyFilters } from 'bindings/reports/generic/GenericReport';
import { Granularity } from 'bindings/time/Granularity';
import listWithPenImage from 'images/icon/list_with_pen.svg';
import optionGrey from 'images/icon/three_dots.svg';
import * as moment from 'moment';
import * as React from 'react';
import { Translate, getTranslate } from 'react-localize-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import type { Granularity as ValidatorGranularity } from 'validators-web';
import * as wasm from 'validators-web';
import { AAdditionalColumns, additionalColumnTypeToFilterTypeAndPath, getAdditionalColumns, getFirstValueNotPieChart } from '../../../atoms/additionalColumns';
import { AAdditionalFieldColumns, fieldTypeToFilterType } from '../../../atoms/additionalFieldColumns';
import { AAllBrands, ABrand } from '../../../atoms/filter/BrandFiltersAtom';
import { AFormTemplatePicker } from '../../../atoms/filter/formPicker';
import { AProductFilter } from '../../../atoms/filter/productsFilterAtom';
import { ATagFilter } from '../../../atoms/filter/tagsFilterAtom';
import { ADatePicker, AGranularitySelect, ATimeRangeFilter3, AVariationSelect } from '../../../atoms/filter/timeFilterAtom';
import { AUserPicker } from '../../../atoms/filter/usersFilterAtom';
import { AUsers } from '../../../atoms/global/users';
import { AViews, DbView, NewView, ReportView, deleteView, getViews, postView, putView } from '../../../atoms/global/views';
import { AProducts, Product } from '../../../atoms/product';
import { AtomCategory } from '../../../atoms/utils/model/Model';
import Dropdown from '../../../components_v2/dropdown/Dropdown';
import { DropdownData } from '../../../components_v2/dropdown/model/Model';
import { FilterResult, filterTreeLength, filterTreeLength } from '../../../components_v2/filter/model/Model';
import { Filter, FilterParameter, FilterParameterParent, convertFilters } from '../../../components_v2/filter/pages/FilterList';
import Input from '../../../components_v2/input/Input';
import PopupDeletion from '../../../components_v2/popup/PopupDeletion';
import ToolbarFilter, { ToolbarElement, ToolbarFilterElement } from '../../../components_v2/toolbarFilter/ToolbarFilter';
import BrandFilter from '../../../components_v2/toolbarFilter/brands/BrandFilter';
import FormTemplateTemplatePicker from '../../../components_v2/toolbarFilter/forms/FormTemplatePicker';
import ProductPerCategoryFilter from '../../../components_v2/toolbarFilter/products/productPerCategoryFilter';
import { TagType } from '../../../components_v2/toolbarFilter/tags/Model';
import TagFilter from '../../../components_v2/toolbarFilter/tags/tagFilter';
import GranularitySelect from '../../../components_v2/toolbarFilter/time/GranularitySelect';
import TimeRangeFilter3, { TimeRange3ToDate } from '../../../components_v2/toolbarFilter/time/TimeRangeFilter3';
import VariationSelect from '../../../components_v2/toolbarFilter/time/VariationSelect';
import UserPicker from '../../../components_v2/toolbarFilter/users/userPicker';
import { dateToNaiveDateTime, momentToNaiveDateTime, useWindowDimensions } from '../../../components_v2/utils';
import PopupView, { ViewCreation } from '../../../components_v2/view/PopupView';
import ViewPicker from '../../../components_v2/view/ViewPicker';
import storeLang from '../../../helpers/storeLang';
import RefreshButton from '../../../myToolbar/RefreshButton';
import { DefaultButton } from '../../../styles/global/css/GlobalButton';
import { translateToString } from '../../../styles/global/translate';
import { useFunctionState } from '../../../utils/customHooks';
import { AlertContext, AlertRes } from '../../alert/AlertProvider';
import { getCompanyFilters } from '../../client-companies/data/action';
import { SidebarButton } from '../../client-companies/style/Style';
import { ToolbarState } from '../../globals/mainPage/mainPage';
import { LoadingStateEnum } from '../../import/model';
import { ComponentLoader } from '../../map/modalRight/ModalCalendar';
import NoData from '../../noData/NoData';
import PermissionContext from '../../permissions/PermissionContext';
import { ModalState } from '../../products/model';
import { FlexDiv } from '../../products/style';
import { syncTagsAndStatuses } from '../../settings/subSettings/actions';
import { reportingAction, reportingState } from '../Reducer';
import { ReportInterpretorNoProvider } from '../interpretor/ReportInterpretor';
import { Report } from '../interpretor/bareReportingDecoder';
import ReportInterpretorContext, { ReportInterpretorProvider, ReportInterpretorProviderLinksProps } from '../interpretor/hoverContext';
import { createGenericReport, createNewAdditionalColumn, deleteGenericReport, getGenericAndDecodeReport, getGenericReport, getReportPresignedUrl, updateGenericReport } from './action';
import { exportReport } from './export';
import { setLocalReport } from './localReport';
import { CalculatedFieldTarget, postCalculatedFields, CalculatedField, ACalculatedFields } from '../../../atoms/calculatedFields';
import * as _ from 'lodash';
import { ACompanies } from '../../../atoms/clientCompany';
import { AllPeriods } from './genericEditor';
import { Period } from 'bindings/reports/generic/TimeRange';
import { assert } from '../../../utils/assert';

export function filterProducts(tags: (string)[], products: Product[]): Product[] {
	return products.filter(product => {
		let result = product.tags.includes(+tags[0]);
		for (let i = 1; i < tags.length; i += 2) {
			const condition = tags[i];
			const tag = +tags[i + 1];
			if (condition === 'and') {
				result = result && product.tags.includes(tag);
			} else if (condition === 'or') {
				result = result || product.tags.includes(tag);
			}
		}
		return result;
	});
}

type PostCalculatedField = Omit<CalculatedField, 'id'>

type GenericProps = {
	state: reportingState
	reprasentation: GenericReport,
	dispatch: (action: reportingAction) => void
	setToolBarState: (value: ToolbarState) => void
	updateGenericReports: () => Promise<void>
};

export type LocalFilters = {
	[key: string]: unknown
} & { my_filters?: MyFilters };

type jsx = { name: string, child: JSX.Element};

export function initFilters(report: GenericReport): { filters: LocalFilters, right_jsx: jsx[], form_jsx: jsx[], product_jsx: jsx[] } {
	const localfilters: LocalFilters = {
		variation: report.evolution || report.sum ? undefined : report.variation,
		evolution: report.evolution,
		form_filter: report.form_filter,
		created_by_filter: report.created_by_filter,
		product_filter: report.product_filter,
		filter: report.filter,
		my_filters: report.my_filters
	};


	return { filters: localfilters, right_jsx: [
		{
			name: translateToString(report.evolution ? 'analysis_granularity' : 'analysis_variation'),
			child: report.evolution ? <GranularitySelect fullScreen context /> : <VariationSelect fullScreen context />,
		},
		{
			name: translateToString('forms_taken_into_account'),
			child: <TimeRangeFilter3 context fullScreen />
		}
	],
	form_jsx: [
		{
			name: translateToString('form_creator'),
			child: <UserPicker logo={listWithPenImage} atomId={0} deleted={false} context permission={'ViewReporting'} fullScreen />
		},
		{
			name: translateToString('form_template'),
			child: <FormTemplateTemplatePicker context fullScreen />
		},
	],
	product_jsx: [
		{
			name: translateToString('product_categories'),
			child: <ProductPerCategoryFilter context atomId={0} fullScreen />
		},
		{
			name: translateToString('brands'),
			child: <BrandFilter context fullScreen />
		},
		{
			name: translateToString('products_tags'),
			child: <TagFilter fullScreen context atomId={0} tagType={'product'} />
		}

	]
	};
}

export const ReportFiltersContext = React.createContext<{
	filters: LocalFilters
	setFilters: React.Dispatch<React.SetStateAction<LocalFilters>>
	jsxFilters: jsx[][]
		}>({ filters: {} as LocalFilters, setFilters: () => undefined, jsxFilters: [] });

export function ReportFiltersProvider(props: {genericReport: GenericReport, filters?: LocalFilters, setFilters?: (value: LocalFilters) => void, children: React.ReactNode }) {
	const [filters, setFilters] = React.useState<LocalFilters>({});
	const [jsxFilters, setJsxFilters] = React.useState<jsx[][]>([]);
	const [init, setInit] = React.useState(false);
	React.useEffect(() => {
		const { filters, right_jsx, form_jsx, product_jsx } = initFilters(props.genericReport);
		setFilters(filters);
		setJsxFilters([right_jsx, form_jsx, product_jsx]);
		setInit(true);
	}, [props.genericReport]);

	return (
		<ReportFiltersContext.Provider value={{ filters, setFilters, jsxFilters }}>
			{init && props.children}
		</ReportFiltersContext.Provider>
	);
}

export default function Generic(props: GenericProps & ReportInterpretorProviderLinksProps) {
	return <ReportFiltersProvider genericReport={props.reprasentation} filters={{}}>
		<ReportInterpretorProvider onCompanyClick={props.onCompanyClick} onFormInstanceClick={props.onFormInstanceClick}>
			<_Generic {...props} />
		</ReportInterpretorProvider>
	</ReportFiltersProvider>;
}

function launchToast(message: string) {
	toast.info(message, {
		position: 'top-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined
	});
}

export function NewReport(props: { onBreak: () => void, onOk: () => void }) {
	const [name, setName] = React.useState<string>('');
	const history = useHistory();
	return <>
		<Input name='' value={name} type='text' onChange={setName} label={translateToString('name')} />
		<DefaultButton
			margin='0'
			onClick={() => {
				props.onOk();
				createGenericReport({
					'name': name, 'value': {
						'group_by': 'Company',
						'panels': [
							{ 'columns': [] }
						]
					}
				}).then(res => {
					getGenericReport(res).then(report => {
						setLocalReport({
							id: report.id,
							name: report.name,
							report: report.value
						});
						history.push({ pathname: 'reportEditor' });
					});
				}
				);
			}}
			disabled={!name}>
			<Translate id='next' />
		</DefaultButton>
	</>;
}

export function getFinalyFilteredProducts(filters, products) {
	let finaly: Product[] | undefined = undefined;
	if (JSON.stringify(filters) !== '{}') {
		if (filters.my_filters && filters.my_filters.tags !== undefined && filters.my_filters.tags.length != 0) {
			finaly = filterProducts(filters.my_filters.tags, products);
		}
		if (filters.my_filters && filters.my_filters.brands && !filters.my_filters.brands.all) {
			if (finaly) {
				finaly = finaly.filter(e => filters.my_filters?.brands?.brands?.some(b => b == e.brand));
			} else {
				finaly = products.filter(e => filters.my_filters?.brands?.brands?.some(b => b == e.brand));
			}
		}
		if (filters.my_filters?.products && !filters.my_filters?.products?.all) {
			if (finaly) {
				finaly = finaly.filter(e => filters.my_filters?.products?.products.some(p => p == e.id));
			} else {
				finaly = products.filter(e => filters.my_filters?.products?.products?.some(p => p == e.id));
			}
		}
		return finaly?.map(e => e.uuid);
	}
}

function periodToDate(period: string): moment.Moment {
	switch (period) {
		case 'Today': return moment().startOf('day');
		case 'ThisWeek': return moment().startOf('week');
		case '7DaysAgo': return moment().subtract(7, 'days').startOf('day');
		case 'LastWeek': return moment().subtract(1, 'week').startOf('week');
		case 'ThisMonth': return moment().startOf('month');
		case '30DaysAgo': return moment().subtract(30, 'days').startOf('day');
		case 'LastMonth': return moment().subtract(1, 'month').startOf('month');
		case '90DaysAgo': return moment().subtract(90, 'days').startOf('day');
		case 'ThisYear': return moment().startOf('year');
		case '365DaysAgo': return moment().subtract(365, 'days').startOf('day');
		case 'LastYear': return moment().subtract(1, 'year').startOf('year');
		default: return moment().startOf('day');
	}
}

function _Generic(props: GenericProps) {
	const [report, setReport] = React.useState<Report>();
	const [loadingState, setLoadingState] = React.useState<LoadingStateEnum>();
	const [subComponentToolBarState, setSubComponentToolBarState] = React.useState<ToolbarState>();
	const translate = getTranslate(storeLang.getState().localize);
	const { height, width } = useWindowDimensions();
	const [pickedDates, setPickedDates] = useRecoilState(ADatePicker);
	const [filteredProducts, setFilteredProducts] = useRecoilState(AProductFilter);
	const granularity = useRecoilValue(AGranularitySelect);
	const [user, setUser] = useRecoilState(AUserPicker(0));
	const [filterParams, setFilterParams] = React.useState<FilterParameter[] | FilterParameterParent[]>();
	const { filters, setFilters } = React.useContext(ReportFiltersContext);
	const [filterResult, setFilterResult] = React.useState<FilterResult | undefined>((filters?.filter as FilterResult) ?? undefined);
	const products = useRecoilValue(AProducts);
	const companies = useRecoilValue(ACompanies);
	const [brandFilter, setBrandFilter] = useRecoilState(ABrand);
	const [tagsFilter, setTagsFilter] = useRecoilState(ATagFilter);
	const [allBrands, setAllBrands] = useRecoilState(AAllBrands);
	const [finalyFilteredProducts, setFinalyFilteredProducts] = React.useState<{ setted: boolean, value: string[] | undefined }>({ setted: false, value: undefined });
	const variation = useRecoilValue(AVariationSelect);
	const owners = useRecoilValue(AUsers);
	const pickedRange = useRecoilValue(ATimeRangeFilter3);
	const setDataState = useSetRecoilState(AAdditionalColumns);
	const formTemplateTemplate = useRecoilValue(AFormTemplatePicker);
	const refresh = async() => {
		const data = await getAdditionalColumns();
		setDataState(data);
	};
	const history = useHistory();

	// Views
	const [views, setViews] = useRecoilState(AViews);
	const [isViewOpen, setIsViewOpen] = React.useState<boolean>(false);
	const [selectedView, setSelectedView] = useFunctionState<DbView<ReportView> | undefined>(views.reports?.find(v => v.id.toString() === localStorage.getItem('reports_view_id-' + props.state.data.id)),
		({ newValue }) => {
			if (newValue) {
				localStorage.setItem('reports_view_id-' + props.state.data.id, newValue.id.toString());
			} else {
				localStorage.removeItem('reports_view_id-' + props.state.data.id);
			}
			return newValue;
		}
	);
	const [editedView, setEditedView] = React.useState<DbView<ReportView> | undefined>();

	const additionalColumns = useRecoilValue(AAdditionalColumns);
	const additionalFieldColumns = useRecoilValue(AAdditionalFieldColumns);
	const { isAllowedTo } = React.useContext(PermissionContext);
	const { evolutionStateRef } = React.useContext(ReportInterpretorContext);

	const [isDateFuckedUp, setIsDateFuckedUp] = React.useState(false);
	const [companyFilterParameterParent, setCompanyFilterParameterParent] = React.useState<FilterParameterParent>();
	const [deletionModalState, setDeletionModalState] = React.useState<ModalState<DbView<ReportView>>>({ isOpen: false });
	const setCalcFieldAtom = useSetRecoilState(ACalculatedFields);
	const calculatedFields = useRecoilValue(ACalculatedFields);
	
	// DO NOT REMOVE THIS OR THE APP WILL CRASH
	() => console.log(wasm);
	React.useEffect(() => {
		getViews().then(newViews => {
			setSelectedView(views.reports?.find(v => v.id.toString() === localStorage.getItem('reports_view_id-' + props.state.data.id)));
			setAllBrands(true);
			setBrandFilter(undefined);
			setTagsFilter(undefined);
			setUser(undefined);
			setFilteredProducts(undefined);
			setViews(newViews);
		});
		if (props.reprasentation.range && AllPeriods.includes(props.reprasentation.range.begin)) {
			const begin = periodToDate(props.reprasentation.range.begin);
			const end = periodToDate(props.reprasentation.range.end);
			setPickedDates([begin, end]);
		} else {
			setPickedDates([(props.reprasentation.range?.begin) ? moment(props.reprasentation.range.begin) : moment().subtract(3, 'month').startOf('day'), (props.reprasentation.range?.end) ? moment(props.reprasentation.range.end) : moment().endOf('day')]);
		}
		setFinalyFilteredProducts({ setted: false, value: undefined });
		setFilterResult(DEFAULT_FILTER_RESULT);
		setLoadingState(undefined);

	}, [props.state.data?.id]);

	React.useEffect(() => {
		getCompanyFilters().then(async response => {
			const allFirstValues = await getFirstValueNotPieChart();
			const firstValues = allFirstValues.filter(fv => fv.column_type === 'additional_column');
			const newFilterParams = [...response.filters];
			if (isAllowedTo({ objectAction: 'ReadForm' })) {
				newFilterParams.push(
					additionalColumns.reduce((acc: FilterParameter, x) => {
						let type: FilterType;
						let path: string | undefined = undefined;
						if (x.type === 'ReportColumn') {
							const firstValue = firstValues.find(fv => fv.id === x.id);
							if (!firstValue || !firstValue.value) {
								type = 'nullable';
							} else if ('int' in firstValue.value || 'float' in firstValue.value) {
								type = 'numeric';
							} else if ('percentage' in firstValue.value || 'evolution' in firstValue.value) {
								type = 'percentage';
							} else if ('key' in firstValue.value || 'text' in firstValue.value) {
								type = 'string';
							} else {
								type = additionalColumnTypeToFilterTypeAndPath(x.type).type;
							}
							if (firstValue && firstValue.value) {
								path = Object.keys(firstValue.value)[0];
							}
						} else {
							const { type: type_, path: path_ } = additionalColumnTypeToFilterTypeAndPath(x.type);
							type = type_;
							path = path_;
						}
						acc.filters.push({
							id: { additional_columns: [{ id: x.id, path }] },
							name: x.name,
							type
						});
						return acc;
					}, { category: translateToString('additional_columns'), filters: [] })
				);
				newFilterParams.push(
					additionalFieldColumns.reduce((acc: FilterParameter, x) => {
						acc.filters.push({
							id: { additional_field_columns: [x.field_id] },
							name: x.field_name,
							type: fieldTypeToFilterType(x.field_type)
						});
						return acc;
					}, { category: translateToString('additional_field_columns'), filters: [] }));
			}
			const filterNb = filterResult?.formatted ? filterTreeLength(filterResult.formatted) : 0;
			const companyFilterParameterParent = { name: translateToString('global.filter.company_filters'), children: newFilterParams, nbFiltersSet: filterNb };
			setCompanyFilterParameterParent(companyFilterParameterParent);
		});
	}, [filterResult]);

	function checkFilterSet(name: string, filters: LocalFilters): boolean {
		let isSet = false;
		let filterValue: unknown;
		switch (name) {
			case translateToString('form_template'):
				filterValue = filters.form_filter;
				isSet = (filterValue !== undefined && filterValue !== null && (filterValue as number[]).length > 0);
				break;
			case translateToString('form_creator'):
				filterValue = filters.created_by_filter;
				isSet = (filterValue !== undefined && filterValue !== null && (filterValue as number[]).length != owners.length);
				break;
			case translateToString('product_categories'):
				filterValue = filters.product_filter;
				isSet = filters.my_filters?.products ? !filters.my_filters.products?.all :
					(filterValue !== undefined && filterValue !== null && (filterValue as number[]).length > 0);
				break;
			case translateToString('brands'):
				filterValue = filters.my_filters?.brands;
				isSet = (filterValue !== undefined && filterValue !== null && !filters.my_filters?.brands?.all);
				break;
			case translateToString('products_tags'):
				filterValue = filters.my_filters?.tags;
				isSet = (filterValue !== undefined && filterValue !== null && (filterValue as number[]).length > 0);
				break;
		}
		return isSet;
	}

	React.useEffect(() => {
		const res = companyFilterParameterParent ? [companyFilterParameterParent] : [];
		setFilterParams([...res, {
			name: translateToString('products_filters'),
			children: [
				{
					category: translateToString('products_filters'),
					filters: initFilters(props.reprasentation).product_jsx.map(e => {
						return {
							id: e.name,
							name: e.name,
							type: 'nullable',
							jsx: e.child,
							isFilterSet: checkFilterSet(e.name, filters)
						};
					}),
				}
			]
		},
		{
			name: translateToString('forms_filters'), children: [
				{
					category: translateToString('forms_filters'),
					filters: initFilters(props.reprasentation).form_jsx.map(e => {
						return {
							id: e.name,
							name: e.name,
							type: 'nullable',
							jsx: e.child,
							isFilterSet: checkFilterSet(e.name, filters)
						};
					})
				}
			]
		}]);
	}, [props.reprasentation, companyFilterParameterParent, filters]);

	const DEFAULT_FILTER_RESULT: FilterResult = {
		values: {
			array: [],
			combinator: 'and'
		},
		formatted: undefined
	};

	const deleteLocalView = (view: DbView<ReportView>) => {
		if (view) {
			setDeletionModalState({ isOpen: true, data: view });
		}
	};

	const saveView = React.useCallback(async(v: ViewCreation) => {
		const data: NewView<'reports'> = {
			data: {
				filters: filterResult ?? DEFAULT_FILTER_RESULT,
				columns: [],
				name: v.name,
				type: 'reports',
				tagsFilter: tagsFilter,
				brandFilter: brandFilter,
				newFilters: filters,
				filteredProducts: filteredProducts,
				reportId: props.state.data ? props.state.data.id : 0,
				users: user,
				allBrands
			},
			public: v.public
		};
		await (v.id ? putView(v.id, data) : postView(data));
		getViews().then(newViews => {
			setSelectedView(newViews.reports.find(nv => v.id === nv.id || !views.reports.find(v => v.id == nv.id)));
			setViews(newViews);
		});
	}, [views, filterResult, tagsFilter, brandFilter, filteredProducts, user, allBrands, filters]);

	const list: DropdownData[] = [];
	list.push({ label: 'Editer le rapport', value: 1 },
		{ label: 'Dupliquer ce rapport', value: 2 },
		{ label: 'Créer un nouveau rapport', value: 3 },
		{ label: 'Exporter le rapport', value: 4 },
		{ label: 'Supprimer ce rapport', value: 5 },
		{ label: 'Partager le rapport', value: 6 }
	);

	const { alertDelete, alert } = React.useContext(AlertContext);

	React.useEffect(() => {
		if (filters.my_filters?.timerange && pickedDates) {
			const thirdDate = TimeRange3ToDate(filters.my_filters.timerange, pickedDates[0]!.toDate());
			setIsDateFuckedUp(filters.my_filters.timerange != undefined && thirdDate !== undefined && (!pickedDates || moment(thirdDate).isAfter(pickedDates[0])));
		} else {
			setIsDateFuckedUp(false);
		}
	}, [filters.my_filters?.timerange, pickedDates]);

	React.useEffect(() => {
		props.setToolBarState({
			title: translate('report.title').toString(),
			bottomLeftToolbarComponent: <FlexDiv gap='10px'>
				<ViewPicker
					type='reports'
					noBorder
					selectedView={selectedView}
					views={views.reports.filter(v => v.data.reportId === props.state.data.id)}
					onCreate={() => {setSelectedView(undefined); setIsViewOpen(true);}}
					onDelete={deleteLocalView}
					onEdit={() => setIsViewOpen(true)}
					setEditedView={setEditedView}
					onViewChange={view => {
						setSelectedView(view);
						let viewFilters: FilterResult;
						if (view) {
							// set filters according to view
							setTagsFilter(view.data.tagsFilter);
							setBrandFilter(view.data.brandFilter);
							setFilteredProducts(view.data.filteredProducts);
							setAllBrands(view.data.allBrands ?? true);
							setFilters(view.data.newFilters ?? {});
							setUser(view.data.users);
							viewFilters = { ...view.data.filters, formatted: convertFilters(view.data.filters.values) };
							setFilterResult(viewFilters);
							// setFilterResult((filters.filter as FilterResult));
						} else {
							// set default view
							setUser([owners.map(e => e.id), false, true]);
							setAllBrands(true);
							setBrandFilter(undefined);
							setTagsFilter(undefined);
							setFilters(initFilters(props.reprasentation).filters);
							setFilterResult((filters.filter as FilterResult) ?? DEFAULT_FILTER_RESULT);
							setFilteredProducts(undefined);
						}
						setLoadingState(undefined);
					}}
				/>
				{(filterResult?.formatted || (filters.form_filter) || (filters.created_by_filter) || (tagsFilter && tagsFilter?.length > 0) || !allBrands || !user?.[2] || (filteredProducts && !filteredProducts?.all)) &&
					<SidebarButton
						onClick={() => { setIsViewOpen(true); }}><Translate id='save_view' /></SidebarButton>}
				<ToolbarFilter
					permission='ViewReporting'
					maxWidth={width - 550}
					category={AtomCategory.REPORTS}
					elements={[
						{
							kind: ToolbarElement.DATE_PICKER,
							future: true,
							defaultStart: (props.reprasentation.range && props.reprasentation.range.begin) ? moment(props.reprasentation.range.begin) : undefined,
							defaultEnd: (props.reprasentation.range && props.reprasentation.range.end) ? moment(props.reprasentation.range.end) : undefined,
						},
						{
							kind: ToolbarElement.USER_PICKER,
							logo: listWithPenImage,
							hidden: true,
						},
					].filter(e => e) as ToolbarFilterElement[]}
				/>
				{subComponentToolBarState?.bottomLeftToolbarComponent}
				<RefreshButton onFilter={() => setLoadingState(undefined)} isLoading={loadingState == LoadingStateEnum.LOADING} disabled={!user || user[0].length == 0 || (finalyFilteredProducts.value && finalyFilteredProducts.value.length == 0) || isDateFuckedUp} />
			</FlexDiv>,
			bottomRightToolbarComponent: <>{subComponentToolBarState?.bottomRightToolbarComponent ?? <></>}
				{
					<>
						<Dropdown
							datalist={list}
							name='dropdown_actions'
							readOnly
							JSXButton={() => (
								<img
									src={optionGrey}
									className="custom-icon"
									style={{ marginTop: '3px' }}
									alt=""
								/>
							)}
							dropdownStyle={{
								optionWidth: '200px',
								optionLeft: '-175px'
							}}
							onChange={(value: DropdownData) => {
								if (value.value === 1) {
									getGenericReport(props.state.data.id).then(report => {
										setLocalReport({
											id: report.id,
											name: report.name,
											report: report.value
										});
										history.push({ pathname: 'reportEditor' });
									});
								}
								else if (value.value === 2 && report) {
									alert({
										title: 'Nommer le rapport dupliqué',
										content: (onBreak, onOk) => <DuplicateReport oldReport={props.state.data} onBreak={onBreak} onOk={onOk} />,
										noButtons: true,
									});
								}
								else if (value.value === 3) {
									alert({
										title: 'Nommez le nouveau rapport',
										content: (onBreak, onOk) => <NewReport onBreak={onBreak} onOk={onOk} />,
										noButtons: true,
									});
								} else if (value.value == 4 && report) {
									exportReport(report, props.state.data, products, owners, evolutionStateRef, companies);
								}
								else if (value.value === 5) {
									alertDelete({
										name: 'ce rapport ( ' + props.state.data.name + ' )'
									}).then(res => {
										if (res === AlertRes.Ok) {
											deleteGenericReport(props.state.data.id).then(
												() => props.updateGenericReports()
											);
										}
									});
								} else if (value.value === 6 && (pickedDates != null) && pickedDates[0] && pickedDates[1]) {
									report &&
									launchToast(translateToString('reports.copied_link'));
									getReportPresignedUrl({
										...props.reprasentation,
										...filters,
										range: { begin: momentToNaiveDateTime(pickedDates[0]), end: momentToNaiveDateTime(pickedDates[1]) },
										created_by_filter: user && (user[2] ? [] : user[0]),
										product_filter: finalyFilteredProducts.value,
										// variation: props.reprasentation.evolution || props.reprasentation.sum ? undefined : variation,
										filter: filterResult?.formatted,
										// form_filter: formTemplateTemplate,
										// evolution: props.reprasentation.evolution ? granularity : undefined,
										window: (props.reprasentation.variation || props.reprasentation.evolution) && pickedRange != 'thisperiod' ? dateToNaiveDateTime(TimeRange3ToDate(pickedRange, pickedDates[0].toDate())!) : undefined
									}).then(hash => {
										const link = `${window.location.origin}/report-view#${hash}`;
										navigator.clipboard.writeText(link);
									});
								}
							}}
						/></>}
			</>

		});

	}, [props.state.data.id, loadingState, subComponentToolBarState, user, filterResult, finalyFilteredProducts, views, filterParams, filterResult, allBrands, name, isDateFuckedUp, props.state.data, JSON.stringify(filters), props.state.data.id]);

	function DuplicateReport(props: { oldReport: any, onBreak: () => void, onOk: () => void }) {
		const [name, setName] = React.useState<string>('');
		const history = useHistory();
		return <>
			<Input name='' value={name} type='text' onChange={setName} label={translateToString('name')} />
			<DefaultButton
				margin='0'
				onClick={() => {
					props.onOk();
					createGenericReport({ 'name': name, 'value': props.oldReport.value }).then(res => {
						getGenericReport(res).then(report => {
							setLocalReport({
								id: report.id,
								name: report.name,
								report: report.value
							});
							history.push({ pathname: 'reportEditor' });
						});
					}
					);
				}}
				disabled={!name}>
				<Translate id='next' />
			</DefaultButton>
		</>;
	}


	function convertGranularity(g: Granularity): ValidatorGranularity {
		switch (g) {
			case 'day':
				return wasm.Granularity.Day;
			case 'week':
				return wasm.Granularity.Week;
			case 'month':
				return wasm.Granularity.Month;
			case 'quarter':
				return wasm.Granularity.Quarter;
			case 'year':
				return wasm.Granularity.Year;
		}
	}

	React.useEffect(() => {
		if (!loadingState && (pickedDates != null) && pickedDates[0] && pickedDates[1]) {
			if (props.reprasentation.evolution && !wasm.validate_granularity(pickedDates[0].toDate(), pickedDates[1].toDate(), convertGranularity(granularity))) {
				setLoadingState(LoadingStateEnum.ERROR);
			} else try {
				setLoadingState(LoadingStateEnum.LOADING);
				if (props.state.data.value.cancelTokenSource) {
					props.state.data.value.cancelTokenSource.cancel();
				}
				const cancelTokenSource = axios.CancelToken.source();
				props.dispatch({ type: 'SET_CANCEL_TOKEN_SOURCE', value: cancelTokenSource });
				props.dispatch({ type: 'SET_IS_LOADING', value: true });
				const bdy: GenericReport = {
					...props.reprasentation,
					...filters,
					range: { begin: momentToNaiveDateTime(pickedDates[0]), end: momentToNaiveDateTime(pickedDates[1]) },
					product_filter: getFinalyFilteredProducts(filters, products),
					filter: filterResult?.formatted,
					window: filters.my_filters && filters.my_filters.timerange && (props.reprasentation.variation || props.reprasentation.evolution) && filters.my_filters.timerange != 'thisperiod' ? dateToNaiveDateTime(TimeRange3ToDate(filters.my_filters.timerange, pickedDates[0].toDate())!) : undefined
				};
				getGenericAndDecodeReport(bdy).then(report => {
					setReport(report);
					if (loadingState != LoadingStateEnum.LOADED) {
						setLoadingState(LoadingStateEnum.LOADED);
					}
					props.dispatch({ type: 'SET_IS_LOADING', value: false });
				})
					.catch(e => {
						if (axios.isCancel(e)) {
							console.error('Canceling job in: OrderPerProductPerCompany ', e);
						} else {
							console.error(e);
							props.dispatch({ type: 'SET_IS_LOADING', value: false });
						}
						setLoadingState(LoadingStateEnum.ERROR);
					});
			} catch (e) {
				console.error(e);
				setLoadingState(LoadingStateEnum.ERROR);
			}
		}
	}, [selectedView, props.state.data.id, props.state, loadingState, pickedDates, JSON.stringify(filterResult), JSON.stringify(props.reprasentation), user, finalyFilteredProducts.value, finalyFilteredProducts.setted, variation, formTemplateTemplate, tagsFilter, pickedRange, pickedDates, JSON.stringify(filters.my_filters)]);

	const visualizationFilterParams: FilterParameter[] = React.useMemo(() => {
		const filters: Filter[] = initFilters(props.reprasentation).right_jsx.map(e => {
			return {
				id: e.name,
				name: e.name,
				type: 'nullable',
				jsx: e.child,
				isFilterSet: checkFilterSet(e.name, e.child)
			};
		});

		if (!props.reprasentation.evolution && props.reprasentation.sum) {
			filters.splice(0, 1);
		}

		else if (props.reprasentation.evolution) {
			filters.splice(1, 1);
		}

		return [{
			filters,
			category: translateToString('report_parameters'),
		}];
	}, [props.reprasentation.evolution, props.reprasentation.sum, report]);

	if (loadingState === LoadingStateEnum.ERROR) {
		return <NoData message={translateToString('reports.loading_error')} isError={true} height='calc(100% - 10px)'></NoData>;
	}

	if (!report) return <ComponentLoader loadingState={loadingState} allScreen />;

	return <>
		<ReportInterpretorNoProvider
			key={props.state.selectedReport}
			setToolBarState={setSubComponentToolBarState}
			externalActiveFilter={[tagsFilter && tagsFilter?.length > 0, !allBrands, !user?.[2], filteredProducts && !filteredProducts?.all].reduce((acc, t) => t ? acc + 1 : acc, 0)}
			filterResult={filterResult}
			report={report}
			height={height - 260}
			filterParams={filterParams}
			visualizationFitlerParams={visualizationFilterParams}
			onFiltersChange={setFilterResult}
			reprasentation={props.reprasentation}
			context
			onStoreColumn={async(columnIndex, panelIndex, columnName) => {
				const newColumnId = await createNewAdditionalColumn(columnName);
				const newReport = props.state.data;
				const key = Object.keys(newReport.value.panels[panelIndex].columns[columnIndex])[0];
				newReport.value.panels[panelIndex].columns[columnIndex][key].additional_column = newColumnId;
				await updateGenericReport(props.state.data.id, newReport);
				await props.updateGenericReports();
				refresh();
			}}
			onUnstoreColumn={async(columnIndex) => {
				await syncTagsAndStatuses({
					tags: [], statuses: [], field_type: TagType.COMPANY, additional_columns: [
						{ Deletion: columnIndex }
					], additional_field_columns: []
				});
				await props.updateGenericReports();
				refresh();
			}}
			onCreateCalcField={async(columnIndex, panelIndex, columnName) => {
				const report = _.cloneDeep(props.reprasentation);
				const finalyFilteredProducts = getFinalyFilteredProducts(filters, products);
				if (pickedDates === undefined || pickedDates[0] === undefined || pickedDates[1] === undefined)
					return;
				report.range = { begin: momentToNaiveDateTime(pickedDates[0]), end: momentToNaiveDateTime(pickedDates[1]) };
				if (finalyFilteredProducts !== undefined && finalyFilteredProducts.length > 0) {
					report.product_filter = finalyFilteredProducts;
				}
				report.variation = null;
				report.evolution = null;
				report.filter = null;
				report.window = null;
				report.panels[0].columns[0] = report.panels[0].columns[columnIndex];
				report.panels[0].columns = report.panels[0].columns.slice(0, 1);
				if (report.panels[0].products !== undefined) {
					if (panelIndex !== 0 && report.panels[0].products.length > panelIndex - 1) {
						const productPannel = products.find(product => product.uuid === report.panels[0].products![panelIndex - 1]);
						if (productPannel === undefined) return;
						report.my_filters = { ...report.my_filters, products: { all: false, products: [productPannel.id] } };
						report.product_filter = [productPannel.uuid];
					}
					delete report.panels[0].products;
				}
				const calcField: PostCalculatedField = {
					report: report,
					field_name: columnName,
					target: CalculatedFieldTarget.Company
				};
				postCalculatedFields(calcField).then(id => {setCalcFieldAtom([...calculatedFields, { ...calcField, id }]);});
			}}
		/>
		<ComponentLoader loadingState={loadingState} allScreen />
		<PopupDeletion
			records={1}
			translationKey='view'
			noVerification
			isOpen={deletionModalState.isOpen}
			onClickOut={() => setDeletionModalState({ isOpen: false })}
			onValidation={() => {
				if (deletionModalState.data) {
					deleteView(deletionModalState.data.id).then(_ => getViews().then(setViews));
				}
			}}
		/>
		<PopupView
			isOpen={isViewOpen}
			view={editedView ?? selectedView}
			onClickOut={() => { setIsViewOpen(false); setEditedView(undefined); }}
			onValidation={v => {
				saveView(v).then(_ => setIsViewOpen(false));
				setEditedView(undefined);
			}} />
	</>;
}
